<template>
  <div class="page page-xdiffusion-all-jobs">
    <PageHeader :icon="$t('JOB.ICON')" :title="$t('JOB.TITLES')">
      <template slot="nav">
        <small><strong>{{ $tc("JOB.TITLE_COUNT", count) }}</strong></small>
      </template>

      <v-menu offset-y nudge-bottom="5px" :close-on-click="true">
        <v-list nav dense>
          <v-list-item link @click="newItem">
            <v-list-item-icon><v-icon size="20">mdi-plus</v-icon></v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="text-capitalize">
                <strong>{{ $t("COMMON.NEW") }}</strong>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>

      <permission name="job" action="create">
        <v-tooltip bottom transition="none">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              fab
              small
              depressed
              color="success"
              class="ml-2"
              v-bind="attrs"
              v-on="on"
              @click="newItem"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          {{ $t("COMMON.ADD") }}
        </v-tooltip>
      </permission>

    </PageHeader>

    <FormJobStepper
      :show="showNew"
      @close="showNew = false"
      @create="updateItems"
    />

    <Side
      component="forms/job_publication/compact/FormJobPublicationCompact"
      :ids="(selected || []).map((v) => v.id)"
      :value="showPublish"
      :props="{ campaign_id: mainCampaignId || null, createCampaignJobs: true }"
      @close="closePublish"
    />

    <Side
      component="forms/job/compact/FormJobCompact"
      :ids="(selected || []).map((v) => v.id)"
      :value="showEdit"
      @close="closeEdit"
      @create="loadItems"
      @update="loadItems"
      @remove="removeItems"
    />

    <DialogRemove
      :value="showDialogRemove"
      :items="selected"
      item-text="id"
      @confirm="confirmRemoveItems"
      @close="showDialogRemove = false"
      @select="selectItems"
    />

    <List
      :items="items"
      :count="count"
      :offset="offset"
      :limit="limit"
      :pagination="true"
      :loading="loading"
      :selected="selected"
      @applyFilters="applyFilters"
      @resetFilters="resetFilters"
      @select="selectItems"
      @page="changePage"
      @click="selectItem"
      @dblclick="editItem"
      @reload="searchItems"
    >
      <template v-slot:header="">
        <div class="col-4">
          <v-text-field
            ref="where.title"
            hide-details
            outlined
            dense
            clearable
            prepend-inner-icon="mdi-magnify"
            :placeholder="$t('COMMON.SEARCH')"
            @keyup="searchItems"
            @click:clear="searchItems"
          />
        </div>
        <!-- <div class="col-3 pl-1">
          <v-select
            ref="where.status"
            v-model="status"
            :items="statusItems"
            item-text="text"
            item-value="value"
            rounded
            hide-details
            outlined
            dense
            @input="searchItems"
          />
        </div> -->
      </template>

      <template v-slot:filters="">
        <div class="pa-4">

          <v-row>
            <v-col>
              <span class="text-small text-uppercase grey--text">{{ $tc("INPUT.LOCATION_CITY") }}</span>
              <v-autocomplete
                ref="where.location_city"
                :label="$tc('INPUT.LOCATION_CITY')"
                :search-INPUT.sync="locationInput"
                outlined
                dense
                clearable
                :items="cities"
                item-text="name"
                :loading="citiesIsBusy"
                return-object
              />
            </v-col>
          </v-row>

          <v-row class="mt-0">
            <v-col>
              <span class="text-small text-uppercase grey--text">{{ $tc("INPUT.COMPANY") }}</span>
              <v-text-field
                ref="where.company_title"
                :label="$tc('INPUT.COMPANY')"
                clearable
                outlined
                dense
                @keyup="searchItems"
                @click:clear="searchItems"
              />
            </v-col>
          </v-row>

          <!-- <v-row class="mt-0">
            <v-col>
              <span class="text-small text-uppercase grey--text">{{ $tc("INPUT.OCCUPATIONS") }}</span>
              <v-combobox
                ref="where.xtramile_occupation_id"
                small-chips
                clearable
                multiple
                dense
                hide-details
                :placeholder="$t('INPUT.MULTIPLE_VALUE')"
                :items="occupations"
                item-text="title"
                item-value="id"
                outlined
              />
            </v-col>
          </v-row> -->

          <v-row class="mt-0">
            <v-col>
              <span class="text-small text-uppercase grey--text">{{ $tc("INPUT.SECTOR") }}</span>
              <v-combobox
                ref="where.xtramile_occupation_id"
                small-chips
                clearable
                multiple
                dense
                hide-details
                :placeholder="$tc('INPUT.SECTOR')"
                :items="sectorItems"
                item-text="title"
                item-value="id"
                outlined
              />
            </v-col>
          </v-row>
        </div>
      </template>

      <template v-slot:nav="">
        <v-btn-toggle v-model="jobTab" borderless group tile dense rounded>
          <v-btn small value="draft">
            <v-icon left> mdi-file-question-outline</v-icon>
            {{$t('JOB.JOB_DRAFTS')}}
          </v-btn>
          <v-btn small value="archived">
            <v-icon left> mdi-archive-outline</v-icon>
            {{$t('JOB.JOB_ARCHIVED')}}
          </v-btn>
           <v-btn small value="deleted">
            <v-icon left> mdi-delete-outline</v-icon>
            {{$t('JOB.JOB_REMOVED')}}
          </v-btn>
        </v-btn-toggle>
      </template>

      <template v-slot:selections="">
        <v-col cols="12" class="pt-0 pl-0">
          <div>
            <small><strong>{{ $tc("COMMON.SELECTED", +selected.length) }}</strong></small>
          </div>
          <v-btn
            small
            rounded
            depressed
            color="primary"
            class="mr-2"
            @click="selectAllPages"
          >
            <v-icon size="20" class="mr-2">mdi-playlist-plus</v-icon>
            {{ $t("COMMON.SELECT_ALL_PAGES") }}
          </v-btn>

          <v-btn
            small
            rounded
            depressed
            color="primary"
            class="mr-2"
            @click="showPublish = true"
          >
            <v-icon size="20" class="mr-2">mdi-forwardburger</v-icon>
            {{ $t("COMMON.PUBLISH") }}
          </v-btn>

          <v-btn
            small
            rounded
            depressed
            color="primary"
            class="mr-2"
            @click="showEdit = true"
          >
            <v-icon size="20" class="mr-2">mdi-pencil</v-icon>
            {{ $t("COMMON.EDIT") }}
          </v-btn>

          <v-btn
            small
            rounded
            depressed
            color="error"
            class="mr-2"
            @click="showDialogRemove = true"
          >
            <v-icon size="20" class="mr-2">mdi-delete</v-icon>
            {{ $t("COMMON.REMOVE") }}
          </v-btn>
          <v-btn
            small
            rounded
            depressed
            color="secondary custom black--text"
            @click="selectItems([])"
          >
            <v-icon size="20" class="mr-2">mdi-close</v-icon>
            {{ $t("COMMON.CANCEL") }}
          </v-btn>
        </v-col>
      </template>

      <template v-slot:item-list="item">
        <!-- <div v-if="item.status_type" :class="['list-overlay', item.status_type ? item.status_type : '']"/> -->
        <v-list-item-content>
          <v-list-item-title class="primary--text">
            <strong>{{ item.title }}</strong>
            <v-icon
              v-if="item.parent_id"
              :title="item.parent_id"
              color="success"
              class="ml-2"
              size="14"
            >
              {{ $t("COMMON.DUPLICATE_ICON") }}
            </v-icon>
          </v-list-item-title>
          <v-list-item-subtitle>
            <v-icon size="12" :color="item.status > 0 ? 'success' : 'error'"
              >mdi-circle</v-icon
            >
            <v-chip x-small label class="pl-1 ml-1" v-if="item.feed_id">
              <v-icon class="mr-1" size="14">mdi-rss</v-icon>
              {{ item.feed.title }}
            </v-chip>
            <v-chip x-small label class="pl-1 ml-1" v-if="item.company">
              <v-icon class="mr-1" size="14">mdi-domain</v-icon>
              {{ item.company }}
            </v-chip>
            <v-chip x-small label class="pl-1 ml-1" v-if="item.location">
              <v-icon class="mr-1" size="14">mdi-map-marker</v-icon>
              {{ item.location_city + ", " + item.location_city_code }}
            </v-chip>
            <v-chip
              x-small
              label
              class="pl-1 ml-1"
              v-if="item.xtramile_sector_id"
            >
              <v-icon class="mr-1" size="14">mdi-bookmark</v-icon>
              {{ $t("JOB.SECTOR." + item.xtramile_sector_id) }}
            </v-chip>
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <status-label :item="item" small />
        </v-list-item-action>
        <v-list-item-action class="ml-3 date">
          {{ $moment(item.updated_at).fromNow() }}
        </v-list-item-action>
        <v-list-item-action>
          <div>
            <permission name="job" action="update">
              <v-btn icon class="ml-2" @click.stop="removeItem(item)">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </permission>
            <a class="ml-2" :href="item.url" :title="item.url" target="blank">
              <v-btn icon>
                <v-icon>mdi-open-in-new</v-icon>
              </v-btn>
            </a>

            <permission name="job" action="update">
              <v-badge
                :content="getReportCount(item)"
                color="error"
                :value="getReportCount(item) > 0"
                overlap
              >
                <v-btn icon class="ml-2" @click.stop="editItem(item)">
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </v-badge>
            </permission>
          </div>
        </v-list-item-action>
      </template>
    </List>
  </div>
</template>

<script>
import Side from "@/components/Side"
import PageHeader from "@/components/PageHeader"
import DialogRemove from "@/components/DialogRemove"
import List from "@/components/List"
import StatusLabel from "@/components/StatusLabel"
import Permission from "@/components/Permission"
import FormJobStepper from "@/forms/job/stepper/FormJobStepper"

export default {
  name: "PageXdiffusionAllJobs",
  components: {
    Permission,
    Side,
    PageHeader,
    DialogRemove,
    List,
    StatusLabel,
    FormJobStepper,
  },
  data: (_this) => ({
    statusItems: _this.$i18n.t("COMMON.STATUS_ITEMS"),
    jobTab: undefined,
    whereValue: {
      status: 1,
      draft: false,
    },
    locationInput: '',
    locationSearchTimeout: 0,
    locationLoading: false,
    // occupations: undefined,
    loading: false,
    items: [],
    count: 0,
    offset: 0,
    limit: 100,
    page: 1,
    selected: [],
    showPublish: false,
    showNew: false,
    showEdit: false,
    showDialogRemove: false,
    showDialogPermissionEdit: false,
    timeout: null,
    status: [1],
    methods: [],
    campaignsItems: [],
    feedItems: [],
    // filter settings
    sectorItems: [],
    searchCitiesInput: "",
    searchCountriesInput: "",
    countries: [],
    countriesIsBusy: false,
    cities: [],
    citiesIsBusy: false,
    ageRange: [1, 100],
    minAge: 1,
    maxAge: 100,
    mainCampaignId: null,
  }),
  props: ["update", "select"],

  async created() {
    this.countries = (
      await this.$services.api_programmatic.location_country.search()
    ).data.map(({ name: text, code: value }) => ({ text, value }));

    // this.loadOccupations()
    this.loadSectorItems()
  },

  watch: {
    update() {
      this.loadItems();
    },
    select(items) {
      this.selected = items;
    },
    locationInput (n) {
      this.searchLocationItems(n)
    },
  },

  mounted() {
    this.$queryFilters().get();
    this.loadMainCampaign();
    this.searchItems();
    if (this.itemId) {
      this.selectItems([{ id: this.itemId }]);
      this.editSelected();
    }
  },

  computed: {
    itemId() {
      return this.$router.currentRoute.query.edit || undefined;
    },
  },

  methods: {
    getReportCount(item) {
      const report = item.status_report;
      if (report)
        return Object.keys(report).filter((k) => typeof report[k] === "object")
          .length;
      return 0;
    },

    onCitySearch(searchCitiesInput) {
      this.searchCitiesInput = searchCitiesInput;
    },

    onCountrySearch(searchCountriesInput) {
      this.searchCountriesInput = searchCountriesInput;
    },

    onLocationChange(value, propName) {
      this.where[propName] = value;
    },

    formatLocation(str) {
      return (str || "").split(",").slice(0, 2).join(",");
    },

    getFeedItem(feed_id, field) {
      for (let i = 0, len = this.feedItems.length; i < len; i++) {
        const c = this.feedItems[i];
        if (c.id === feed_id) return field ? c[field] : c;
      }
      return "?";
    },

    loadMainCampaign() {
      this.$services.api_programmatic.campaign
        .search({ where: { status: [1], title: "DefaultCampaign" } })
        .then((response) => {
          this.campaignItems = response.data;
          this.mainCampaignId = response.data[0].id;
        });
    },

    loadSectorItems() {
      this.$services.api_programmatic.job_sector.search()
        .then(response => {
          this.sectorItems = response.data
        })
    },

    // Request

    setWhereValue () {
      if (this.jobTab === undefined) { this.whereValue = {status: 1} }
      if (this.jobTab === "draft") { this.whereValue = {draft: true} }
      if (this.jobTab === "archived") { this.whereValue = {status: 0} }
      if (this.jobTab === "deleted") { this.whereValue = {status: -1} }
      this.loadItems()
    },

    removeItems() {
      this.showEdit = false;
      this.$router.push("/xdiffusion/jobs/");
      this.loadItems();
    },

    searchItems(element) {
      this.selected = []
      this.loading = true
      // debounce request
      if (this.timeout) clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.loadItems()
      }, 100)
    },

    loadItems(options = {}) {
      this.loading = true;
      this.$queryFilters().save();
      const defaultOptions = {
        order: [["updated_at", "DESC"]],
        mode: "list",
        page: this.page || 1,
        limit: this.limit,
      };

      const query = this.$queryFormat({
        where: this.whereValue,
        options: Object.assign({}, defaultOptions, { options }),
      })
      this.$emit("childData", { queryList: query })
      this.$services.api_programmatic.job
        .search(query)
        .then((response) => {
          if (response.data) {
            const td = (v) => {
              v.feed = { title: this.getFeedItem(v.feed_id, "title") };
              return v;
            };
            this.items = response.data.items.map(td);
            this.count = response.data.count;
            this.offset = response.data.offset;
            this.limit = response.data.limit;
          } else {
            this.items = [];
            this.count = 0;
            this.offset = 0;
            this.limit = 0;
          }
          this.updateSelected();
        })
        .catch(e => {
          this.$store.dispatch('messenger/add', {type: 'error', code: e.code, message: e.message, time: 5000 })
        })
        .finally(() => {
          this.loading = false;
        });
    },

    changePage(page) {
      this.page = +page;
      this.searchItems();
    },

    confirmRemoveItems() {
      const ids = this.selected.map((v) => v.id);
      this.$services.api_programmatic.job
        .remove({ where: { id: ids } })
        .then((response) => {
          this.$store.dispatch('messenger/add', {type: 'success', code: response.code, message: response.message, time: 3000 })
          this.selected = []
          this.showDialogRemove = false
          this.loadItems()
        })
        .catch(e => {
          this.$store.dispatch('messenger/add', {type: 'error', code: e.code, message: e.message, time: 5000 })
        })
    },

    updateSelected() {
      for (let i = 0, lenI = this.selected.length; i < lenI; i++) {
        const a = this.selected[i];
        for (let j = 0, lenJ = this.items.length; j < lenJ; j++) {
          const b = this.items[j];
          if (a.id === b.id) {
            this.selected[i] = b;
            break;
          }
        }
      }
    },

    // Mutliple items

    removeSelected() {
      this.showDialogRemove = true;
    },

    editSelected() {
      this.showEdit = true;
      if (this.selected.length > 0) {
        const item = this.selected[0];
        this.$router.push(`/xdiffusion/jobs/?edit=${item.id}`);
      }
    },

    selectItem(item) {
      for (let i = 0, len = this.selected.length; i < len; i++) {
        if (this.selected[i].id === item.id) return this.selected.splice(i, 1);
      }
      return this.selected.push(item);
    },

    selectItems(arr) {
      this.selected = arr;
    },

    closeEdit() {
      this.showEdit = false;
      this.$router.push("/xdiffusion/jobs/");
    },

    closePublish() {
      this.showPublish = false;
      this.$router.push("/xdiffusion/jobs/");
    },

    selectAllPages(options) {
      this.loading = true;
      const defaultOptions = {
        order: [["updated_at", "DESC"]],
        attributes: ["id", "updated_at"],
        limit: 100000,
      };
      const query = this.$queryFormat({
        where: { status: 1 },
        options: Object.assign({}, defaultOptions, { options }),
      });

      this.$services.api_programmatic.job
        .search(query)
        .then((response) => {
          if (response.data) {
            this.selected = response.data;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    // Single item

    updateItems() {
      this.loadItems();
    },

    newItem() {
      this.selected = [];
      this.showNew = true;
    },

    editItem(item) {
      this.selected = [item];
      this.$router.push(`/xdiffusion/jobs/?edit=${this.selected[0].id}`);
      this.showEdit = true;
    },

    editPermissionItem(item) {
      this.selected = [item];
      this.showDialogPermissionEdit = true;
    },

    removeItem(item) {
      this.selected = [item];
      this.showDialogRemove = true;
    },

    goToItem(item) {
      // item = item ? item : this.selected[0]
      // const route = `/job/${  item.id}`
      // if (this.$router.currentRoute.path !== route) {
      //   this.$router.push(route)
      // }
    },

    applyFilters() {
      this.searchItems();
    },

    resetFilters() {
      this.applyFilters();
    },

    searchLocationItems (n) {
      const then = (response) => {
        let data = []
        if (response && response.data) data = response.data
        this.cities = data
      }

      if (this.locationSearchTimeout) clearTimeout(this.locationSearchTimeout)
      this.locationLoading = true
      this.locationSearchTimeout = setTimeout(() => {
        if (n) {
          this.$services.api_programmatic
            .location_city.search({where:{title:n}, options: {limit: 10}})
            .then(then)
        }else {
          Promise.resolve().then(then)
        }
      }, 200)
    },

    // async loadOccupations () {
    //   this.loading = true
    //   const response = await this.$services.api_programmatic.job_occupation.search()
    //   this.occupations = response.data
    // },
  },
};
</script>
